<script setup lang="ts">
import { Primitive, type PrimitiveProps } from 'radix-vue';
import type { HTMLAttributes } from 'vue';

import { cn } from '~/components/5x/lib/utils';

import { type ButtonVariants, buttonVariants } from '.';

interface Props extends PrimitiveProps {
  color?: ButtonVariants['color'];
  variant?: ButtonVariants['variant'];
  size?: ButtonVariants['size'];
  class?: HTMLAttributes['class'];
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  as: 'button',
});
</script>

<template>
  <Primitive
    :as="as"
    :as-child="asChild"
    :class="cn(buttonVariants({ color, variant, size }), props.class)"
    :disabled="disabled">
    <slot />
  </Primitive>
</template>
