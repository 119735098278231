import { type VariantProps, cva } from 'class-variance-authority';

export { default as Button } from './Button.vue';

export const buttonColorVariants = {
  primary: '',
  neutral: '',
  teal: '',
  green: '',
  lime: '',
  yellow: '',
  orange: '',
  red: '',
  magenta: '',
  purple: '',
};

export const buttonVariantVariants = {
  solid: 'text-neutral-000',
  solidLight: '',
  white: 'bg-neutral-000',
  outline: 'bg-transparent border',
  ghost: 'bg-transparent hover:underline',
  link: 'bg-transparent underline',
};

export const buttonSizeVariants = {
  sm: 'h-8 rounded text-sm [&>i]:w-4.5 [&>i]:h-4.5',
  md: 'h-10 rounded text-sm [&>i]:w-5 [&>i]:h-5',
  lg: 'h-12 rounded text-base [&>i]:w-5.5 [&>i]:h-5.5',
  xl: 'h-15 rounded text-base [&>i]:w-6 [&>i]:h-6',
  'icon-sm': 'w-8 h-8 [&>i]:w-4.5 [&>i]:h-4.5',
  'icon-md': 'w-10 h-10 [&>i]:w-5 [&>i]:h-5',
  'icon-lg': 'w-13 h-13 [&>i]:w-5.5 [&>i]:h-5.5',
  'icon-xl': 'w-15 h-15 [&>i]:w-6 [&>i]:h-6',
};

export const buttonVariants = cva(
  'inline-flex items-center justify-center gap-1.5 whitespace-nowrap rounded-md text-sm font-semibold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 cursor-pointer disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: buttonVariantVariants,
      size: buttonSizeVariants,
      color: buttonColorVariants,
    },
    defaultVariants: {
      color: 'primary',
      variant: 'solid',
      size: 'md',
    },
    compoundVariants: [
      {
        variant: ['solid', 'solidLight', 'white', 'outline'],
        size: 'sm',
        className: 'px-2.5',
      },
      {
        variant: ['solid', 'solidLight', 'white', 'outline'],
        size: 'md',
        className: 'px-3',
      },
      {
        variant: ['solid', 'solidLight', 'white', 'outline'],
        size: 'lg',
        className: 'px-4',
      },
      {
        variant: ['solid', 'solidLight', 'white', 'outline'],
        size: 'xl',
        className: 'px-4',
      },
      {
        variant: ['ghost', 'link'],
        size: ['sm', 'md', 'lg', 'xl'],
        className: 'px-0',
      },
      {
        color: 'primary',
        variant: 'solid',
        className: 'bg-primary-600 sm:hover:bg-primary-700 focus-visible:ring-primary-500',
      },
      {
        color: 'primary',
        variant: 'solidLight',
        className: 'text-primary-600 bg-primary-100 sm:hover:bg-primary-200 focus-visible:ring-primary-500',
      },
      {
        color: 'primary',
        variant: 'white',
        className: 'text-primary-600 sm:hover:bg-primary-100 focus-visible:ring-primary-500',
      },
      {
        color: 'primary',
        variant: 'outline',
        className: 'text-primary-600 border-primary-400 sm:hover:bg-primary-alpha-200 focus-visible:ring-primary-500',
      },
      {
        color: 'primary',
        variant: 'ghost',
        className: 'text-primary-600 focus-visible:ring-primary-500',
      },
      {
        color: 'primary',
        variant: 'ghost',
        size: ['icon-sm', 'icon-md', 'icon-lg', 'icon-xl'],
        className: 'sm:hover:bg-primary-alpha-200',
      },
      {
        color: 'primary',
        variant: 'link',
        className: 'text-primary-600 sm:hover:text-primary-800 focus-visible:ring-primary-500',
      },
      {
        color: 'neutral',
        variant: 'solid',
        className: 'bg-neutral-900 sm:hover:bg-neutral-1000 focus-visible:ring-neutral-1000',
      },
      {
        color: 'neutral',
        variant: 'solidLight',
        className: 'text-neutral-1000 bg-neutral-200 sm:hover:bg-neutral-300 focus-visible:ring-neutral-500',
      },
      {
        color: 'neutral',
        variant: 'white',
        className: 'text-neutral-1000 sm:hover:bg-neutral-200 focus-visible:ring-neutral-500',
      },
      {
        color: 'neutral',
        variant: 'outline',
        className:
          'text-neutral-1000 border-neutral-alpha-300 sm:hover:bg-neutral-alpha-200 focus-visible:ring-neutral-500',
      },
      {
        color: 'neutral',
        variant: 'ghost',
        className: 'text-neutral-1000 focus-visible:ring-neutral-500',
      },
      {
        color: 'neutral',
        variant: 'ghost',
        size: ['icon-sm', 'icon-md', 'icon-lg', 'icon-xl'],
        className: 'sm:hover:bg-neutral-alpha-200',
      },
      {
        color: 'neutral',
        variant: 'link',
        className: 'text-neutral-1000 sm:hover:text-neutral-800 focus-visible:ring-neutral-500',
      },
      {
        color: 'teal',
        variant: 'solid',
        className: 'bg-teal-600 sm:hover:bg-teal-700 focus-visible:ring-teal-500',
      },
      {
        color: 'teal',
        variant: 'solidLight',
        className: 'text-teal-600 bg-teal-100 sm:hover:bg-teal-200 focus-visible:ring-teal-500',
      },
      {
        color: 'teal',
        variant: 'white',
        className: 'text-teal-600 sm:hover:bg-teal-100 focus-visible:ring-teal-500',
      },
      {
        color: 'teal',
        variant: 'outline',
        className: 'text-teal-600 border-teal-400 sm:hover:bg-teal-alpha-200 focus-visible:ring-teal-500',
      },
      {
        color: 'teal',
        variant: 'ghost',
        className: 'text-teal-600 focus-visible:ring-teal-500',
      },
      {
        color: 'teal',
        variant: 'ghost',
        size: ['icon-sm', 'icon-md', 'icon-lg', 'icon-xl'],
        className: 'sm:hover:bg-teal-alpha-200',
      },
      {
        color: 'teal',
        variant: 'link',
        className: 'text-teal-600 sm:hover:text-teal-800 focus-visible:ring-teal-500',
      },
      {
        color: 'green',
        variant: 'solid',
        className: 'bg-green-600 sm:hover:bg-green-700 focus-visible:ring-green-500',
      },
      {
        color: 'green',
        variant: 'solidLight',
        className: 'text-green-600 bg-green-100 sm:hover:bg-green-200 focus-visible:ring-green-500',
      },
      {
        color: 'green',
        variant: 'white',
        className: 'text-green-600 sm:hover:bg-green-100 focus-visible:ring-green-500',
      },
      {
        color: 'green',
        variant: 'outline',
        className: 'text-green-600 border-green-400 sm:hover:bg-green-alpha-200 focus-visible:ring-green-500',
      },
      {
        color: 'green',
        variant: 'ghost',
        className: 'text-green-600 focus-visible:ring-green-500',
      },
      {
        color: 'green',
        variant: 'ghost',
        size: ['icon-sm', 'icon-md', 'icon-lg', 'icon-xl'],
        className: 'sm:hover:bg-green-alpha-200',
      },
      {
        color: 'green',
        variant: 'link',
        className: 'text-green-600 sm:hover:text-green-800 focus-visible:ring-green-500',
      },
      {
        color: 'lime',
        variant: 'solid',
        className: 'bg-lime-600 sm:hover:bg-lime-700 focus-visible:ring-lime-500',
      },
      {
        color: 'lime',
        variant: 'solidLight',
        className: 'text-lime-600 bg-lime-100 sm:hover:bg-lime-200 focus-visible:ring-lime-500',
      },
      {
        color: 'lime',
        variant: 'white',
        className: 'text-lime-600 sm:hover:bg-lime-100 focus-visible:ring-lime-500',
      },
      {
        color: 'lime',
        variant: 'outline',
        className: 'text-lime-600 border-lime-400 sm:hover:bg-lime-alpha-200 focus-visible:ring-lime-500',
      },
      {
        color: 'lime',
        variant: 'ghost',
        className: 'text-lime-600 focus-visible:ring-lime-500',
      },
      {
        color: 'lime',
        variant: 'ghost',
        size: ['icon-sm', 'icon-md', 'icon-lg', 'icon-xl'],
        className: 'sm:hover:bg-lime-alpha-200',
      },
      {
        color: 'lime',
        variant: 'link',
        className: 'text-lime-600 sm:hover:text-lime-800 focus-visible:ring-lime-500',
      },
      {
        color: 'yellow',
        variant: 'solid',
        className: 'bg-yellow-600 sm:hover:bg-yellow-700 focus-visible:ring-yellow-500',
      },
      {
        color: 'yellow',
        variant: 'solidLight',
        className: 'text-yellow-600 bg-yellow-100 sm:hover:bg-yellow-200 focus-visible:ring-yellow-500',
      },
      {
        color: 'yellow',
        variant: 'white',
        className: 'text-yellow-600 sm:hover:bg-yellow-100 focus-visible:ring-yellow-500',
      },
      {
        color: 'yellow',
        variant: 'outline',
        className: 'text-yellow-600 border-yellow-400 sm:hover:bg-yellow-alpha-200 focus-visible:ring-yellow-500',
      },
      {
        color: 'yellow',
        variant: 'ghost',
        className: 'text-yellow-600 focus-visible:ring-yellow-500',
      },
      {
        color: 'yellow',
        variant: 'ghost',
        size: ['icon-sm', 'icon-md', 'icon-lg', 'icon-xl'],
        className: 'sm:hover:bg-yellow-alpha-200',
      },
      {
        color: 'yellow',
        variant: 'link',
        className: 'text-yellow-600 sm:hover:text-yellow-800 focus-visible:ring-yellow-500',
      },
      {
        color: 'orange',
        variant: 'solid',
        className: 'bg-orange-600 sm:hover:bg-orange-700 focus-visible:ring-orange-500',
      },
      {
        color: 'orange',
        variant: 'solidLight',
        className: 'text-orange-600 bg-orange-100 sm:hover:bg-orange-200 focus-visible:ring-orange-500',
      },
      {
        color: 'orange',
        variant: 'white',
        className: 'text-orange-600 sm:hover:bg-orange-100 focus-visible:ring-orange-500',
      },
      {
        color: 'orange',
        variant: 'outline',
        className: 'text-orange-600 border-orange-400 sm:hover:bg-orange-alpha-200 focus-visible:ring-orange-500',
      },
      {
        color: 'orange',
        variant: 'ghost',
        className: 'text-orange-600 focus-visible:ring-orange-500',
      },
      {
        color: 'orange',
        variant: 'ghost',
        size: ['icon-sm', 'icon-md', 'icon-lg', 'icon-xl'],
        className: 'sm:hover:bg-orange-alpha-200',
      },
      {
        color: 'orange',
        variant: 'link',
        className: 'text-orange-600 sm:hover:text-orange-800 focus-visible:ring-orange-500',
      },
      {
        color: 'red',
        variant: 'solid',
        className: 'bg-red-600 sm:hover:bg-red-700 focus-visible:ring-red-500',
      },
      {
        color: 'red',
        variant: 'solidLight',
        className: 'text-red-600 bg-red-100 sm:hover:bg-red-200 focus-visible:ring-red-500',
      },
      {
        color: 'red',
        variant: 'white',
        className: 'text-red-600 sm:hover:bg-red-100 focus-visible:ring-red-500',
      },
      {
        color: 'red',
        variant: 'outline',
        className: 'text-red-600 border-red-400 sm:hover:bg-red-alpha-200 focus-visible:ring-red-500',
      },
      {
        color: 'red',
        variant: 'ghost',
        className: 'text-red-600 focus-visible:ring-red-500',
      },
      {
        color: 'red',
        variant: 'ghost',
        size: ['icon-sm', 'icon-md', 'icon-lg', 'icon-xl'],
        className: 'sm:hover:bg-red-alpha-200',
      },
      {
        color: 'red',
        variant: 'link',
        className: 'text-red-600 sm:hover:text-red-800 focus-visible:ring-red-500',
      },
      {
        color: 'magenta',
        variant: 'solid',
        className: 'bg-magenta-600 sm:hover:bg-magenta-700 focus-visible:ring-magenta-500',
      },
      {
        color: 'magenta',
        variant: 'solidLight',
        className: 'text-magenta-600 bg-magenta-100 sm:hover:bg-magenta-200 focus-visible:ring-magenta-500',
      },
      {
        color: 'magenta',
        variant: 'white',
        className: 'text-magenta-600 sm:hover:bg-magenta-100 focus-visible:ring-magenta-500',
      },
      {
        color: 'magenta',
        variant: 'outline',
        className: 'text-magenta-600 border-magenta-400 sm:hover:bg-magenta-alpha-200 focus-visible:ring-magenta-500',
      },
      {
        color: 'magenta',
        variant: 'ghost',
        className: 'text-magenta-600 focus-visible:ring-magenta-500',
      },
      {
        color: 'magenta',
        variant: 'ghost',
        size: ['icon-sm', 'icon-md', 'icon-lg', 'icon-xl'],
        className: 'sm:hover:bg-magenta-alpha-200',
      },
      {
        color: 'magenta',
        variant: 'link',
        className: 'text-magenta-600 sm:hover:text-magenta-800 focus-visible:ring-magenta-500',
      },
      {
        color: 'purple',
        variant: 'solid',
        className: 'bg-purple-600 sm:hover:bg-purple-700 focus-visible:ring-purple-500',
      },
      {
        color: 'purple',
        variant: 'solidLight',
        className: 'text-purple-600 bg-purple-100 sm:hover:bg-purple-200 focus-visible:ring-purple-500',
      },
      {
        color: 'purple',
        variant: 'white',
        className: 'text-purple-600 sm:hover:bg-purple-100 focus-visible:ring-purple-500',
      },
      {
        color: 'purple',
        variant: 'outline',
        className: 'text-purple-600 border-purple-400 sm:hover:bg-purple-alpha-200 focus-visible:ring-purple-500',
      },
      {
        color: 'purple',
        variant: 'ghost',
        className: 'text-purple-600 focus-visible:ring-purple-500',
      },
      {
        color: 'purple',
        variant: 'ghost',
        size: ['icon-sm', 'icon-md', 'icon-lg', 'icon-xl'],
        className: 'sm:hover:bg-purple-alpha-200',
      },
      {
        color: 'purple',
        variant: 'link',
        className: 'text-purple-600 sm:hover:text-purple-800 focus-visible:ring-purple-500',
      },
    ],
  },
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;
